@import "../constants";
@import "../mixins/blockButtonMixins";
@import "../mixins/svgiconMixins";


.vehicleLicensePlate {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: stretch;
    border-radius: var(--sf-border-radius);
    border: 1px solid rgba(0, 0, 0, .5);
    //box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    background-color: white;
    height: var(--sf-btn-sm-height);
    width: 144px;
    background-image: linear-gradient(200deg, rgba(0, 0, 0, 0.03), rgba(255, 255, 255, 0.1));
}

.vehicleLicensePlate__countryPart {
    user-select: none;
    margin: 1px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--sf-color-eu-blue);
    border-radius: calc(var(--sf-border-radius) - 1px);
    color: white;
    width: 14%;
    justify-content: space-around;
}

.vehicleLicensePlate__countryFlag {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        display: block;
        border-radius: 1px;
        max-width: 80%;
        -webkit-user-drag: none;
    }

    .svgicon {
        color: var(--sf-color-eu-yellow);
        @include svgicon-size(14px);
    }
}

.vehicleLicensePlate__countryCode {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    line-height: 10px;
    height: 10px;
    font-weight: var(--sf-font-weight-bold);
}

.vehicleLicensePlate__numberPart {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: var(--sf-font-weight-bold);
    letter-spacing: .05em;
    color: black;
    font-size: 20px;
    overflow: hidden;
}


.vehicleLicensePlate.--GB,
.vehicleLicensePlate.--NL {
    background-color: var(--sf-color-eu-yellow);

    .vehicleLicensePlate__countryPart {
        color: var(--sf-color-eu-yellow);
    }
}