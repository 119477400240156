@import "../constants";

.userVehicle {
    // Remove the margin from the UserVehiclePage
    .pageSection__container {
        margin: 0;
    }
}

.userVehicleLogin {
    display: flex;
    border-radius: 8px;
    background-color: var(--sf-text-color);
    opacity: 0.25;
    padding: 32px;
    align-items: center;
    margin: 24px 0 32px;
}

.userVehicleLogin__icon {
    color: var(--sf-text-color);
    opacity: 0.75;
}

.userVehicleLogin__instructions {
    display: flex;
    flex-direction: column;
    color: var(--sf-text-color);
    opacity: 0.75;
    flex-grow: 1;
    margin-left: 20px;
}

.userVehicle__vehicleHistory {
    margin-top: 32px;

    .userVehicle__editButton {
        display: none;
    }

    &.--hidden {
        display: none
    }
}

.userVehicleParkButton {
    margin-right: 16px !important;
}

.userVehicleParkButton__label {
    @media (max-width: $screen-600px-1) {
        display: none;
    }
}

.userVehicle__item {
    display: flex;
    padding: 24px;
    border-bottom: 1px solid var(--sf-line-color-light);
    align-items: center;
    position: relative;

    &.--hidden {
        display: none;
    }

    @media (max-width: $screen-900px-1) {
        flex-direction: column;
        align-items: start;
        padding: 24px 0;
    }
}

.userVehicle__link {
    font-weight: var(--sf-font-weight-bold);
}

.userVehicle__imageContainer {
    max-width: 32px;
    overflow: hidden;
    margin-right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $screen-900px-1) {
        margin-right: 0;
        margin-bottom: 8px;
    }
}

.userVehicle__image {
    width: 100%;
    height: auto;
}

.userVehicle__info {
    flex-grow: 1;
    div {
        &.--hidden {
            display: none;
        }
    }
}

.userVehicle__itemButtons {
    position: absolute;
    right: 24px;
    bottom: 24px;
    display: flex;
    align-items: center;
    @media (max-width: $screen-900px-1) {
        position: relative;
        right: initial;
        bottom: initial;
        margin-top: 12px;
    }
}

.userVehicle__partsButton,
.userVehicle__editButton {
    line-height: 1;
    color: var(--sf-text-color);
    opacity: 0.65;
    display: flex;
    align-items: center;

    .svgicon {
        margin-left: 8px;
    }
}

.userVehicle__backButton,
.userVehicle__partsButton {
    line-height: 1;
    .svgicon {
        max-width: 12px;
        max-height: 12px;
    }
}

.userVehicle__editButton {
    margin-right: 32px;
}

.userVehicle__parkButton {
    background-color: var(--sf-text-color);
    opacity: 0.75;
    border-radius: var(--sf-border-radius);
    color: white;
    padding: 6px 12px;
    margin-right: 32px;
}

.userVehicle__vehicleHistoryDelete,
.userVehicle__delete,
.userVehicle__backButton {
    color: var(--sf-text-color);
    opacity: 0.65;
    display: block;

    &:hover {
        text-decoration: underline;
    }
}

.userVehicle__vehicleHistoryDelete,
.userVehicle__delete {
    margin-top: 24px;
    text-decoration: underline;
    cursor: pointer;
}

.userVehicle__backButton {
    margin: 24px 0;
    display: flex;
    align-items: center;

    .svgicon {
        margin-right: 8px;
    }
}

.userVehicle__previewItem,
.userVehicle__editItem,
.userVehicle__newItem {
    margin-bottom: 24px;

    .userVehicle__parkButton,
    .userVehicle__editButton {
        display: none;
    }
}

.userVehicle__name {
    font-weight: var(--sf-font-weight-bold);
}

.userVehicle__parkedVehicles {
    margin-top: 32px;
}

.userVehicle__itemList {
    .userVehicle__parkButton {
        display: none;
    }
}

.userVehicle__search {
    margin-bottom: 16px;
    position: relative;
}

.userVehicle__searchIcon {
    position: absolute;
    right: 0;
    width: 40px;
    left: auto;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--sf-form-control-border-color);
}

.userVehicle__motorCodes,
.userVehicle__powerWithYears {
    color: var(--sf-text-color);
    opacity: 0.65;
}

.userVehicle__vin {
    max-width: max-content;
}

.userVehicle__vin,
.userVehicle__licensePlate,
.userVehicle__technicalInspectionExpirationDate,
.userVehicle__tollPassExpirationDate {
    margin: 4px 0;
}

.userVehicle__technicalInspectionExpirationDate,
.userVehicle__tollPassExpirationDate {
    display: flex;
    align-items: center;
    color: var(--sf-text-color);
    opacity: 0.65;

    .svgicon {
        margin-inline-end: 8px;
    }

    &.--expired {
        color: red;
        font-weight: var(--sf-font-weight-bold);
        opacity: 1;
    }

    &.--soon {
        color: orange;
        font-weight: var(--sf-font-weight-bold);
        opacity: 1;
    }
}

.userVehicle__tollPassExpirationDate {
    margin-inline-start: 8px;
}

.userVehicleLogin__loginButton {
    padding: 8px 16px;
}

.userVehicle__noMessage {
    display: none;
    &.--visible {
        display: block;
    }
}

.userVehicle__row {
    display: flex;
}